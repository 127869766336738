import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
     <>
     <div className="footer">
     <div className="container-fluid fulwidth">
         <div className="row">
             <div className="col-xl-3 col-lg-6 col-md-12">
                 <img src="/Images/footerlogo.png"  className="img-fluid footerlogo"/>
             </div>
             <div className="col-xl-3 col-lg-6 col-md-6 divider">
                 <div className="cont-dtl">
                    <h3>Quick Links</h3>
                     <div className="quick-link">
                
                     <ul>
                         <li><Link to="/PhotoGallery"> <img src="/Images/footerli.png" className="img-fluid"/> Photo Gallery</Link></li>
                         <li><Link to="/VideoGallery"> <img src="/Images/footerli.png" className="img-fluid"/> Video Gallery</Link></li>
                         <li><Link to="/Sitemap"> <img src="/Images/footerli.png" className="img-fluid"/> Sitemap</Link></li> 
                     </ul>
                     <ul> 
                         <li><Link to="/ParentGuidline"> <img src="/Images/footerli.png" className="img-fluid"/> Portal Help?</Link></li> 
                         <li><Link to="/ErpSoftware"> <img src="/Images/footerli.png" className="img-fluid"/> ERP Software</Link></li>
                         <li><Link to="/Tc"> <img src="/Images/footerli.png" className="img-fluid"/> TC Issued</Link></li>
                     </ul>
                 </div>
                 </div>
             </div>
             <div className="col-xl-3 col-lg-6 col-md-6">
                 <div className="cont-dtl">
                     <h3>School Addresss</h3>
                     <p><Link to="https://maps.app.goo.gl/D8F1NoN6GUV9T18Q6" target="_blank"> <img src="/Images/locicn.png" className="img-fluid"/> S.P. Mukherjee Marg, Delhi-110006</Link></p>
                     <p><Link to="#"><img src="/Images/locicn1.png" className="img-fluid"/> Between Red Fort & Old Delhi Railway Station</Link></p>
                     <p><Link to="mailto:mail@pcsdelhi.in"><img src="/Images/mailicn.png" className="img-fluid"/> mail@pcsdelhi.in</Link></p>
                     <p><Link to="tel:011-23862862"><img src="/Images/phnicn.png" className="img-fluid"/> 011-23862862, 23865210</Link></p>
                  </div>
             </div>
             
             <div className="col-xl-3 col-lg-6 col-md-12">
             <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14004.008371725035!2d77.235056!3d28.659656!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd0354614c21%3A0x89a66de1d31b80a4!2sPresentation%20Convent%20Senior%20Secondary%20School!5e0!3m2!1sen!2sin!4v1719300325764!5m2!1sen!2sin"  width="100%"  border="0px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
              
             <div className="socialicn">
                 <ul>
                    <li><Link to="#"><img src="/Images/twittericn.png" className="img-fluid"/></Link></li>
                    <li><Link to="#"><img src="/Images/instaicn.png" className="img-fluid"/></Link></li>
                    <li><Link to="#"><img src="/Images/fbicn.png" className="img-fluid"/></Link></li>
                    <li><Link to="#"><img src="/Images/linkicn.png" className="img-fluid"/></Link></li>
                 </ul>
             </div>
               <div className="copyright">
                   <p>©️ Copyright 2018-2024 PCS, Delhi.All Rights Reserved  |  Created by Entab Infotech : <Link to="#"> CampusCare®️</Link> </p>
               </div>
         </div>
     </div>
     
     </div> 
     </>
  )
}

export default Footer
