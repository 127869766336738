import { useState, useEffect } from 'react';
import Header from '../Component/Header';
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import { getQuestionPaper } from '../Service/Api';

const QuestionPapers = () => {
  useEffect(() => {
    // Set the page title
    document.title = 'Question Papers - Presentation Convent School Delhi';
  }, []);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedClass, setSelectedClass] = useState('9'); // Default to Class 9

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for DD/MM/YYYY format
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getQuestionPaper();
      console.log('Fetched data:', response); // Debug: Check the structure of the fetched data
      setData(response);
    };
    fetchData();
  }, []);

  // Handle change for class selection
  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  useEffect(() => {
    const filterData = () => {
      console.log('Selected Class:', selectedClass); // Debug: Log the selected class
      console.log('Current Data:', data); // Debug: Log the current data

      // Adjust the filtering logic to match class with the select dropdown values
      const filtered = data.filter(
        (item) => item.category === 'QuestionPapers' && item.class === parseInt(selectedClass) // Ensure 'class' is correctly parsed
      );
      
      console.log('Filtered Data:', filtered); // Debug: Log the filtered data
      setFilteredData(filtered);
    };
    filterData();
  }, [selectedClass, data]); // Run this effect whenever selectedClass or data changes

  const renderTable = () => (
    <div className="col-lg-12">
      <div className="table-responsive maintable">
        <table className="table">
          <thead>
            <tr>
              <th width="10%"><b>Sl no.</b></th>
              <th width="15%"><b>Date</b></th>
              <th width="65%"><b>Title</b></th>
              <th width="10%"><b>Download</b></th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{formatDate(item.date)}</td>
                <td>{item.title}</td>
                <td className="download">
                  <a href={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank" rel="noopener noreferrer">View More</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <>
      <Header />
      <div className="innerslide">
        <ul className="breadcrumb">
          <li><Link to="/"> Home</Link></li>
          <li>Academic</li>
          <li>Question Papers</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Question Papers</h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select id="chooseddlYear" onChange={handleClassChange} value={selectedClass}>
                  <option value="9">Class 9</option>
                  <option value="10">Class 10</option>
                  <option value="11">Class 11</option>
                  <option value="12">Class 12</option>
                </select>
              </div>
            </div>
          </div>

          <div className="clr"></div>

          {renderTable()}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuestionPapers;
