import { useEffect, useState } from 'react'; 
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
import {getPrincipalMessage} from'../Service/Api'
const PrincipalMessage = () => {
    useEffect(() => {
        // Set the page title
        document.title = 'Principal Message - Presentation Convent School Delhi';  
      }, []);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1,img:"/Images/principal.jpg",category:"Principal",name:"Name"}
   ];
  return (
     <>
       <Header/>
        <div className="innerslide"> 
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                <li>Principal's Message</li>
            </ul>
       </div>
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Principal's Message</h1>
                    </div>
                </div>
                
                {data.length > 0 ? data.map((item) => (
                    <div className="clearfix">
                     <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
                
                       <p><div dangerouslySetInnerHTML={{ __html: item.message }} /></p> 
                      <p className="name"> <div dangerouslySetInnerHTML={{ __html: item.name }} /><span> <div dangerouslySetInnerHTML={{ __html: item.category }} /> </span></p>
              
                 
                </div> 
                )) : emptyData.map((data) => (
                    <div className="clearfix"> 
                    <img src={data.img} className="img-fluid col-md-4 float-md-end mb-3 msgimg" alt=""/>
                    <p>{data.message }</p> 
                     <p className="name"> {data.name} <span> {data.category} </span>
                  
                    </p> 
                    </div> 
                    ))}
             </div>
             
        </div> 
       <Footer/>
     </>
  )
}

export default PrincipalMessage
